@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@font-face {
    font-family: 'Neue Montreal Med';
    src: url('assets/Neue Montreal/NeueMontreal-Medium.otf');
}

@font-face {
    font-family: 'Neue Montreal';
    src: url('assets/Neue Montreal/NeueMontreal-Regular.otf');
}

@font-face {
    font-family: 'Neue Montreal Light';
    src: url('assets/Neue Montreal/NeueMontreal-Light.otf');
}

.App {
    /* Global styles */
    font-family: 'Proxima Nova', sans-serif;
}
