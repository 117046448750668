.Projects {
    text-align: center;
    background: #353535;
    padding: 2.5em;
    overflow: hidden;
}

.ProjectsHeader {

    h1 {
        pointer-events: none;
        animation: fadeIn 2s ease-in-out forwards;
        opacity: 0;
        color: #fff;
        font-size: 2.5em;
        margin: 0;
        font-family: 'DM Sans', sans-serif;
        font-weight: 600;
    }

    p {
        pointer-events: none;
        animation: fadeIn 2s ease-in-out forwards;
        color: #fff;
        font-size: 1em;
        margin: 0;
        font-weight: 400;
    }
}

.aEmailLink {
    pointer-events: auto;
    color: #fff;
    text-decoration: none;
    transition: color 600ms;

    &:hover {
        color: rgb(111, 214, 255);
    }
}

.ProjectsContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 2.5em;
    margin: 2.5em 3.5em;
    padding: 1em;
    overflow: hidden;
}

.ProjectCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.ProjectCard {
    opacity: 0;
    position: relative;
    pointer-events: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    
    width: 40em;
    height: 12em;
    border-radius: 3em;
    padding: 2em;

    box-shadow: 0px 0px 15px 0px #000;
    
    transition: width 800ms ease;

    &:hover {
        transition: width 400ms ease;
        width: 60em;

        .ExpandedProjectTextBox {
            transition: opacity 250ms ease-in 75ms;
            opacity: 1;
        }
    }

    &.COdd {
        pointer-events: auto;
        margin-left: auto;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    
        background: radial-gradient(
            circle at 75% 50%,
            #fff 0%,
            #ddd 50%,
            #c5c5c5 100%
        );
    }

    &.CEven {
        pointer-events: auto;
        margin-right: auto;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        
        background: radial-gradient(
            circle at 25% 50%,
            #fff 0%,
            #ddd 50%,
            #c5c5c5 100%
        );
    }

    .ProjectTextBox {
        position: absolute;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        padding: 0 .5em;
        width: 25em;

        &.TOdd { right: 15em; }
        &.TEven { left: 15em; }

        h2 {
            pointer-events: none;
            font-family: 'DM Sans', sans-serif;
            font-size: 1.5em;
            font-weight: 600;
            margin: 0;
        }

        p {
            color: #000;
            pointer-events: none;
            font-family: 'DM Sans', sans-serif;
            font-size: 1em;
            font-weight: 400;
            margin: 0;
        }
    }

    .ExpandedProjectTextBox {
        opacity: 0;
        position: relative;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        padding: 0 .5em;
        width: 20em;
        height: 12em;

        transition: opacity 200ms ease;

        &.ETOdd { margin-right: auto; }
        &.ETEven { margin-left: auto; left: 1em; }

        h2 {
            pointer-events: none;
            font-family: 'DM Sans', sans-serif;
            font-size: 1.5em;
            font-weight: 600;
            margin: 0;
        }

        p {
            color: #000;
            pointer-events: none;
            font-family: 'DM Sans', sans-serif;
            font-size: 1em;
            font-weight: 400;
            margin: 0;
            padding-left: 1em;
            text-align: left;
        }
    }

    .ProjectImage {
        position: relative;
        pointer-events: none;
        width: 11em;
        height: 12em;
        padding: 0 .5em;

        &.IOdd { margin-left: auto; }
        &.IEven { margin-right: auto; }

        img {
            position: relative;
            object-fit: scale-down;
            width: 100%;
            height: 100%;
        }
    }
}

.slideInLeft {
    animation: leftSlideIn 1s ease-in-out forwards;
}

.slideInRight {
    animation: rightSlideIn 1s ease-in-out forwards;
}

@keyframes rightSlideIn {
    0% { transform: translateX(-150%); opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
}

@keyframes leftSlideIn {
    0% { transform: translateX(150%);  opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .ProjectsContainer {
        margin: 1em 1em;
    }
  
    .ProjectCardContainer {
        flex-direction: column;
    }

    .ProjectsHeader {
        h1 {
            display: none;
        }

        p {
            font-size: .95em;
        }
    }
  
    .ProjectCard {
        opacity: 1;
        flex-direction: column;
        width: 90%;
        gap: 1em;
        height: auto;
        padding: 1em;

        &:hover {
            width: 90%;
        }
    
        .ProjectTextBox, .ExpandedProjectTextBox {
            opacity: 1;
            position: relative;
            width: 100%;
            height: fit-content;
            padding: 0.5em;
            text-align: center;
        }
    
        .ProjectImage {
            width: 100%;
            height: auto;
            margin: 0;
        }
    
        .ProjectImage img {
            width: 100%; /* or a specific percentage to maintain aspect ratio */
            height: auto;
        }
    }

    .ProjectCard.COdd, .ProjectCard.CEven {
        border-radius: 1.5em;
    }

    .ProjectCard {
        .ProjectTextBox {
            &.TOdd, &.TEven {
                margin-left: 0;
                margin-right: 0;
                left: 0;
                right: 0;
            }
        }
        .ExpandedProjectTextBox {
            &.ETOdd, &.ETEven {
                margin-left: 0;
                margin-right: 0;
                left: 0;
                right: 0;
            }
        }
        .ProjectImage {
            &.IOdd, &.IEven {
                margin-left: 0;
                margin-right: 0;
                left: 0;
                right: 0;
            }
        }
    }
  
    .slideInLeft, .slideInRight {
        animation: none;
    }
}
