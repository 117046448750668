.Home {
    text-align: center;
    background: #353535;
    width: auto;
    padding: 2.5em;
}

.Skills {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5em;
    
    margin: 5em auto;
    width: 55em;
}

.Experiences {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5em;
    
    margin: 5em auto;
    width: 55em;
}

.HomeCard {
    position: relative;
    pointer-events: auto;
    width: 15em;
    height: 15em;
    border-radius: 15px;
    background: #000;

    display: flex;
    justify-content: center;
    text-align: center;
    transition: 250ms ease;
}

.HomeCard:hover .HomeCardText {
    transition: 500ms ease;
    background: #454545;
}

.HomeCard::after {
    filter: blur(10px);
}

.HomeCard::before, .HomeCard::after {
    transition: 250ms ease;
    content: '';
    z-index: 0;
    position: absolute;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    top: -3px;
    left: -3px;
    border-radius: 15px;
    background: linear-gradient(45deg,
    #ffec7f, #ffde22, #ffec7f, #ffde22, #ffec7f, #ffde22, #ffec7f,
    #ffde22, #ffec7f, #ffde22, #ffec7f, #ffde22, #ffec7f, #ffde22
    );
    background-size: 300%;
    animation: border 5s linear infinite;
}

.HomeCard:hover::before, .HomeCard:hover::after {
    transition: 250ms ease;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    top: -6px;
    left: -6px;
    animation: border 2.5s linear infinite;
}

.HomeCardText {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .5em;
    padding: 1em;
    text-align: center;
    transition: 500ms ease;

    pointer-events: none;
    z-index: 1;
    height: 13em;
    border-radius: 15px;
    background: #353535;
    color: #fff;

    h3 {
        font-size: 1.5em;
        font-family: 'Neue Montreal Med';
        letter-spacing: 1.5px;
        padding: none;
        margin: 0;
    }

    p {
        font-size: 1em;
        font-family: 'Neue Montreal Light';
        letter-spacing: 0.75px;
        font-weight: 800;
        margin: 0;
        padding: none;
    }
}

.line1 {
    pointer-events: none;
    color: #FFF;
    font-size: 3em;
    margin: 0.5em 0;
}

.line2 {
    pointer-events: none;
    color: #FFF;
    font-size: 2em;
    margin: 0.5em 0;
}

.line3 {
    pointer-events: none;
    color: #FFF;
    font-size: 1.5em;
    margin: 0.5em 7.5em 3.5em;
}

.line4 {
    pointer-events: none;
    color: #FFF;
    font-size: 2em;
    margin: 0.5em 7.5em;
}

.line5 {
    pointer-events: none;
    color: #FFF;
    font-size: 2em;
    margin: 0.5em 7.5em;

}

.line6 {
    pointer-events: none;
    color: #FFF;
    font-size: 2em;
    margin: 0.5em 7.5em 1em;
}

.line7 {
    pointer-events: none;
    color: #FFF;
    font-size: 2em;
    margin: 0.5em 7.5em;

    a {
        pointer-events: auto;
        color: #fff3ad;
        text-decoration: none;
        font-weight: normal;
        text-decoration: underline 2px;
    
        transition: color 750ms ease;
    
        &:hover {
            color: #ffde22;
            transition: 750ms ease;
        }
    }
}

@keyframes border {
    0%, 100% { background-position: 0 0; }
    50% { background-position: 100% 0; }
}

.observe {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
}

.fade-in {
    opacity: 1;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .Skills, .Experiences {
        flex-direction: column;
        width: auto;
        gap: 2em;
    }
  
    .HomeCard {
        width: 12em;
        height: 12em;
        padding: .25em;
    }

    .HomeCard::after, .HomeCard::before {
        width: calc(100% + 1px);
        height: calc(100% + 1px);
        top: -.5px;
        left: -.5px;
    }

    .HomeCardText {
        height: 10em;
    }
  
    .HomeCardText h3, .HomeCardText p {
        font-size: smaller;
    }
  
    .line1, .line2, .line3, .line4, .line5, .line6, .line7 {
        font-size: smaller;
        margin: 0.5em 1em;
    }

    .line1 {
        font-size: 2em;
    }

    .line2 {
        font-size: 1.5em;
    }
  
    .line3, .line4, .line5, .line6, .line7 {
        text-align: center;
    }

    .line7 {
        margin-bottom: 2em;
    }
}
