.About {
    text-align: center;
    background: #353535;
    height: 100vh;
    width: auto;
    padding: 5em 2.5em;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2em;
}

.ProfileCard {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20em;
    height: 20em;
    border-radius: 3em;
    padding: 2em;
    background: #000;
    box-shadow: 0px 0px 15px 0px #000;

    img {
        width: 75%;
        height: 75%;
        border-radius: 50%;
    }

    animation: fadeIn 1s ease-in forwards;
}


.AboutCard {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 40em;
    height: 20em;
    border-radius: 3em;
    padding: 2em;
    box-shadow: 0px 0px 15px 0px #000;
    opacity: 0;

    background: radial-gradient(
        circle at 50% 50%,
        #fff 0%,
        #ddd 50%,
        #c5c5c5 100%
    );

    h1 {
        pointer-events: none;
        font-family: 'Neue Montreal Med';
        font-size: 2.5em;
        margin: 0 0 .5em;
    }

    p {
        pointer-events: none;
        font-family: 'Neue Montreal';
        font-size: 1em;
        line-height: 1.5em;
        margin: 0;
    }

    a {
        pointer-events: auto;
        text-decoration: none;
        color: #000;
        transition: color 250ms ease;
    
        &:hover {
            color: #e7ab2a;
            transition: 250ms ease;
        }
    }

    animation: fadeIn 850ms ease-in forwards 500ms;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .About {
        flex-direction: column;
        padding: 2em 1em;
        height: auto;
    }
  
    .ProfileCard, .AboutCard {
        width: 90%;
        height: auto;
        border-radius: 1.5em;
        padding: 1em;
    }
  
    .ProfileCard img {
        width: 50%;
        height: auto;
    }
  
    .AboutCard {
        padding: 1em;
    }
  
    .AboutCard h1 {
        font-size: 2em;
    }
  
    .AboutCard p {
        font-size: 0.9em;
    }
}
