.Layout {
    height: 15vh;
    /* width: 100vw; */
    background-color: #222222;
}

nav {
    height: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #222222;
    font-size: 1.5em;
    padding: 1em 2em;

    img {
        /* width: 25%; */
        height: 100%;
        scale: 1.5;
        margin-right: 0.5em;
    }

    p {
        font-size: 0.75em;
        margin-left: auto;
        color: white;
    }
}

.Link {
    color: white;
    text-decoration: none;
    margin: 0 1em;
    font-weight: normal;

    transition: color 750ms ease, transform 750ms ease;

    &:hover {
        color: #ffe760;
        transition: 750ms ease;
        transform: scale(1.1);
    }
    
    &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 50%;
        width: 0; 
        height: 2px;
        background-color: #FFD700;
        transition: width 0.5s ease-in-out;
        transform: translateX(-50%);
    }

    &:hover::after {
        width: 100%;
    }
}

.EmailLink {
    color: white;
    text-decoration: none;
    font-weight: normal;
    margin-left: auto;

    transition: color 750ms ease;

    &:hover {
        color: #ffe760;
        transition: 750ms ease;
    }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .Layout {
        height: 5em;
    }
  
    nav {
        flex-direction: row;
        justify-content: center;
        gap: 1em;
        padding: 0.5em;
        font-size: 1em;
    }

    nav img {
        margin: 0;
        margin-left: 1em;
        width: fit-content;
    }

    nav a.EmailLink {
        display: none;
    }
  
    img {
      height: auto;
      width: 50%; /* Adjust based on the actual logo size */
      margin: 0.5em 0;
    }
  
    p {
      font-size: 0.75em;
    }
}
